import { Controller } from "@hotwired/stimulus"
import YouTubePlayer from "youtube-player"

export default class extends Controller {
  static values = {
    identifier: String,
    duration: Number,
    startPoint: Number
  }

  connect() {
    this.player = YouTubePlayer(this.element, {
      width: '100%',               // Player width (in px)
      height: '100%',              // Player height (in px)
      playerVars: {
        'autoplay': 1,        // Auto-play the video on load
        'autohide': 0         // Hide video controls when playing,
      }
    });

    const startSeconds = this.durationValue * this.startPointValue;
    this.player.loadVideoById({'videoId': this.identifierValue, 'startSeconds': startSeconds});
    this.player.mute();
  }
}
