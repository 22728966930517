import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'wrapper', 'cancel' ];

  connect(){
    this.element.modal = this;
  }

  open(e) {
    e.preventDefault();
    this.wrapperTarget.classList.remove('hidden');
    this.cancelTarget.href = window.location.href;
  }

  close(e) {
    e.preventDefault();
    this.wrapperTarget.classList.add('hidden');
  }
}
