import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'main' ];

  connect(){
    this.element.modal = this;
  }

  open(e) {
    e.preventDefault();
    this.mainTarget.classList.add('--open');
  }

  close(e) {
    e.preventDefault();
    this.mainTarget.classList.remove('--open');
  }

  handleBackgroundClick(e) {
    if ( e.target === e.currentTarget ){
      this.close(e);
    }
  }
}
