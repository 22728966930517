import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'group', 'colours', 'submit', 'back', 'radio' ];

  connect(){
    this.hideBeltGroups();
    this.disableSubmit();
  }

  selectColour(e){
    this.hideBeltGroups();

    const beltGroupIndex = e.target.dataset.beltIndex;
    this.groupTargets[beltGroupIndex].classList.remove('hidden');
    this.coloursTarget.classList.add('hidden');
    this.backTarget.classList.remove('invisible');
  }

  selectGrade(e) {
    e.target.parentNode.querySelectorAll('.belt-picker__belt-option').forEach(function(element){
      element.classList.add('opacity-50');
    });

    e.target.classList.remove('opacity-50');
    this.enableSubmit();
  }

  hideBeltGroups() {
    this.groupTargets.forEach(function(element){
      element.classList.add('hidden');
    });
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
    this.submitTarget.classList.add('--disabled');
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
    this.submitTarget.classList.remove('--disabled');
  }

  backToBeltSelection(){
    this.hideBeltGroups();
    this.coloursTarget.classList.remove('hidden');
    this.backTarget.classList.add('invisible');
    this.resetSelections();
  }

  resetSelections() {
    this.disableSubmit();
    this.element.querySelectorAll('.belt-picker__belt-option').forEach(function(element){
      element.classList.remove('opacity-50');
    });
    this.radioTargets.forEach(function(radio){
      radio.checked = false;
    });
  }
}
