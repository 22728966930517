import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ 'element' ];

  initialize() {
    this.index = 0;
  }

  connect() {
    this.updateElements();
    this.boundArrowPress = this.arrowPress.bind(this);
    this.boundPreventKeyScroll = this.preventKeyScroll.bind(this);
    document.addEventListener('keyup', this.boundArrowPress);
    document.addEventListener('keydown', this.boundPreventKeyScroll);
  }

  disconnect() {
    document.removeEventListener('keyup', this.boundArrowPress);
    document.removeEventListener('keyup', this.boundPreventKeyScroll);
  }

  arrowPress(e){
    if (e.key === "ArrowUp") {
      if ( this.index > 0 ){
        this.decrementIndex();
      }
    } else if (e.key === "ArrowDown") {
      if ( this.index < (this.elementTargets.length - 1) ){
        this.incrementIndex();
      }
    }
  }

  preventKeyScroll(e) {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  }

  updateElements() {
    this.elementTargets.forEach((element, index) => {
      if ( index !== this.index ){
        element.classList.add('hidden');
        element.innerHTML = '';
      } else {
        if ( element.hasAttribute('complete') ) {
          element.reload();
        }

        element.classList.remove('hidden');
      }

      if ( index === this.index ) {
        element.classList.add('highlighted');
      } else {
        element.classList.remove('highlighted');
      }
    });
  }

  incrementIndex(){
    this.index++;
    this.updateElements();
  }

  decrementIndex(){
    this.index--;
    this.updateElements();
  }
}
