import { Controller } from "@hotwired/stimulus"
import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  // You can override the `onUpdate` method here.
  add(e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    const position = this.targetTarget.querySelectorAll('.position').length;

    this.targetTarget.insertAdjacentHTML('beforeend', content);
    this.targetTarget.lastElementChild.querySelector('.position').value = position;
  }
}
