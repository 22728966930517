import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import Reveal from 'stimulus-reveal-controller'
import Timeago from 'stimulus-timeago'

const application = Application.start()
const context = require.context('./controllers', true, /\.[jt]s$/);
application.load(definitionsFromContext(context))
application.debug = process.env.NODE_ENV === 'development';

application.register('reveal', Reveal)
application.register('timeago', Timeago)

// Configure Stimulus development experience
window.Stimulus   = application

// export { application }








