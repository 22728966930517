import { Controller } from '@hotwired/stimulus';
import { get } from "@rails/request.js"

let debounce = require("lodash.debounce");

export default class extends Controller {
  static targets = [
    "hidden",
    "results",
    "wrapper",
    "selection",
    "input",
    "searcher",
    "feedback",
    "length",
    "notfound"
  ];
  static values = {
    schoolId: Number,
    schoolName: String
  }

  initialize(){
    this.search = debounce(this.search, 300).bind(this);
  }

  connect() {
    if ( this.schoolIdValue && this.schoolNameValue ){
      this.setSchool(this.schoolIdValue, this.schoolNameValue);
    }
  }

  search(e) {
    if ( e.target.value.length >= 3 ) {
      this.lengthTarget.classList.add('hidden');
      get(e.target.dataset.url, {
        responseKind: "turbo-stream",
        query: { query: e.target.value, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
      }).then((response) => {
        const resultCount = parseInt(response.headers.get("Nuveer-Total-Results"));
        if ( resultCount === 0 ){
          this.notfoundTarget.classList.remove('hidden');
        } else {
          this.notfoundTarget.classList.add('hidden');
        }
        this.resultsTarget.classList.remove('hidden')
      });
    } else {
      this.lengthTarget.classList.remove('hidden');
      this.resultsTarget.classList.add('hidden');
      this.resultsTarget.innerHTML = "";
      this.notfoundTarget.classList.add('hidden');
    }
  }

  select(e) {
    const schoolId = parseInt(e.currentTarget.dataset.schoolId);
    const schoolName = e.currentTarget.dataset.schoolName;
    this.setSchool(schoolId, schoolName);
  }

  setSchool(schoolId, schoolName){
    this.hiddenTarget.value = schoolId;
    this.selectionTarget.innerHTML = schoolName;
    this.wrapperTarget.classList.remove('hidden');
    this.searcherTarget.classList.add('hidden');
    this.resultsTarget.classList.add('hidden');
    this.element.classList.remove('school-search--active');
  }

  closePicker(){
    this.element.classList.remove('school-search--active');
  }

  openSearch(e){
    this.hiddenTarget.value = "";
    this.selectionTarget.innerHTML = "";
    this.wrapperTarget.classList.add('hidden');
    this.searcherTarget.classList.remove('hidden');
    this.resultsTarget.classList.remove('hidden');
    this.inputTarget.focus();
  }

  blur(e){
    setTimeout(() => {
      this.resultsTarget.classList.add('hidden');
    }, 200);
  }

  focus(e){
    this.element.classList.add('school-search--active');
    if ( this.inputTarget.value.length > 2 ){
      this.search(e);
    }
  }
}
