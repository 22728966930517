import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [ 'option', 'form' ];

  connect() {
    this.boundKeyPress = this.keyPress.bind(this);
    setTimeout(() => { this.activate() }, 250);
  }

  disconnect() {
    this.deactivate();
  }

  activate(){
    this.element.classList.add('highlighted');
    document.addEventListener('keyup', this.boundKeyPress);
  }

  deactivate() {
    document.removeEventListener('keyup', this.boundKeyPress);
  }

  moveToNext() {
    setTimeout(() => {
      const element = document.getElementById('work-queue');
      const controller = this.application.getControllerForElementAndIdentifier(element, 'work-queue');
      controller.incrementIndex();
    }, 250);
  }

  keyPress(e){
    if ( e.key >= '1' && e.key <= '9' ) {
      const index = parseInt(e.key) - 1;
      const option = this.optionTargets[index];
      if ( option !== undefined ){
        option.checked = true;

        const changeEvent = new Event('input', {
          bubbles: true,  // Ensure it bubbles up the DOM tree
          cancelable: true // Allow it to be cancelled
        });

        // Dispatch the 'change' event on the radio button
        option.dispatchEvent(changeEvent);
      }
    }
  }

  toggle(e) {
    Rails.fire(this.formTarget, 'submit');
  }
}
