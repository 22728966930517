import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "element" ]

  connect() {
    this.partitions = this.buildPartitions();
    this.setupPartitions();
  }

  togglePartition(e) {
    const index = e.target.dataset.partition;
    const partition = this.partitions[index];
    partition.elements.forEach((element) => {
      element.classList.toggle('hidden');
    });
    if ( partition.elements[0].classList.contains('hidden') ) {
      partition.link.firstChild.innerHTML = `Show ${partition.elements.length} skipped sessions`;
    } else {
      partition.link.firstChild.innerHTML = `Hide ${partition.elements.length} skipped sessions`;
    }
  }

  buildPartitions(){
    let partitions = [];
    let partition = [];

    this.elementTargets.forEach(function(e) {
      if (e.dataset.attending === 'true') {
        if ( partition.length > 0 ) {
          partitions.push( { link: null, elements: partition } );
          partition = [];
        }
      } else {
        partition.push(e);
      }
    });

    if ( partition.length > 0 ) {
      partitions.push( { link: null, elements: partition } );
      partition = [];
    }

    return partitions;
  }

  setupPartitions(){
    this.partitions.forEach((partition, index) => {
      const newNode = document.createElement("a");
      newNode.innerHTML = `Show ${partition.elements.length} skipped sessions`;
      newNode.setAttribute('href', 'javascript:void(0)');
      newNode.setAttribute('data-partition', index);
      newNode.setAttribute('data-action', 'training-session-list#togglePartition');
      newNode.classList.add('show-skipped-session-link');

      const outerNode = document.createElement("div");
      outerNode.classList.add('partition-header');
      outerNode.appendChild(newNode);

      partition.link = this.element.insertBefore(outerNode, partition.elements[0]);

      partition.elements.forEach(function(element){
        element.classList.add('hidden');
      });
    });
  }
}
