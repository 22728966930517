import { Controller } from "@hotwired/stimulus"
import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  connect() {
    super.connect()
  }

  // You can override the `onUpdate` method here.
  onUpdate(_) {
    const elements = Array.from(this.element.querySelectorAll('.position'));
    elements.forEach((element, index) => {
      element.value = index + 1;
    });
  }
}
