import { Controller } from '@hotwired/stimulus';
import tomSelectInput from "../tomSelectInput";

export default class extends Controller {
  connect(){
    tomSelectInput(this.element, this.selected);
  }

  get selected() {
    return [...this.element.options]
        .filter(option => option.selected)
        .map(option => option.value);
  }
}
